import { Image } from "react-grid-gallery";

export const images = [
  {
    src: `${process.env.PUBLIC_URL}/gallery/autumn-park.png`,
    original: `${process.env.PUBLIC_URL}/gallery/autumn-park.png`,
    width: 2923,
    height: 2923,
    caption: "Autumn leaves in a sunny park",
  },
  {
    src: `${process.env.PUBLIC_URL}/gallery/cello.jpg`,
    original: `${process.env.PUBLIC_URL}/gallery/cello.jpg`,
    width: 2268,
    height: 3357,
    caption: "Just a whimisical moment of music and nature",
  },
  {
    src: `${process.env.PUBLIC_URL}/gallery/cityscape.png`,
    original: `${process.env.PUBLIC_URL}/gallery/cityscape.png`,
    width: 3082,
    height: 3082,
    caption: "A freehand cityscape at sunset",
  },
  {
    src: `${process.env.PUBLIC_URL}/gallery/dark-and-stormy-night.png`,
    original: `${process.env.PUBLIC_URL}/gallery/dark-and-stormy-night.png`,
    width: 1842,
    height: 1842,
    caption: "It was a dark and stormy night...",
  },
  {
    src: `${process.env.PUBLIC_URL}/gallery/divine-hunt.jpg`,
    original: `${process.env.PUBLIC_URL}/gallery/divine-hunt.jpg`,
    width: 2975,
    height: 2250,
    caption: "A fox that could not be caught, a hound that will forever pursue",
  },
  {
    src: `${process.env.PUBLIC_URL}/gallery/drummer.jpg`,
    original: `${process.env.PUBLIC_URL}/gallery/drummer.jpg`,
    width: 1933,
    height: 2879,
    caption: "A golden drummer",
  },
  {
    src: `${process.env.PUBLIC_URL}/gallery/fiddler-in-the-stars.jpg`,
    original: `${process.env.PUBLIC_URL}/gallery/fiddler-in-the-stars.jpg`,
    width: 3059,
    height: 2268,
    caption: "A fiddler in the stars",
  },
  {
    src: `${process.env.PUBLIC_URL}/gallery/gate-to-elysium.jpg`,
    original: `${process.env.PUBLIC_URL}/gallery/gate-to-elysium.jpg`,
    width: 3010,
    height: 2229,
    caption: "",
  },
  {
    src: `${process.env.PUBLIC_URL}/gallery/headless-horseman.jpg`,
    original: `${process.env.PUBLIC_URL}/gallery/headless-horseman.jpg`,
    width: 2845,
    height: 2046,
    caption: "He disappeared in a flash of fire and brimstone",
  },
  {
    src: `${process.env.PUBLIC_URL}/gallery/high-seas.png`,
    original: `${process.env.PUBLIC_URL}/gallery/high-seas.png`,
    width: 2996,
    height: 2996,
    caption: "Rough sailing on the high seas",
  },
  {
    src: `${process.env.PUBLIC_URL}/gallery/lantern.png`,
    original: `${process.env.PUBLIC_URL}/gallery/lantern.png`,
    width: 2987,
    height: 2987,
    caption: "",
  },
  {
    src: `${process.env.PUBLIC_URL}/gallery/luminous-library.jpg`,
    original: `${process.env.PUBLIC_URL}/gallery/luminous-library.jpg`,
    width: 2268,
    height: 2985,
    caption: "The knowledge you are looking for",
  },
  {
    src: `${process.env.PUBLIC_URL}/gallery/octopus-waves.jpg`,
    original: `${process.env.PUBLIC_URL}/gallery/octopus-waves.jpg`,
    width: 1072,
    height: 3463,
    caption: "Can you guess which Hokusai piece inspired this?",
  },
  {
    src: `${process.env.PUBLIC_URL}/gallery/outdoors-at-brattle-bookshop.jpg`,
    original: `${process.env.PUBLIC_URL}/gallery/outdoors-at-brattle-bookshop.jpg`,
    width: 2992,
    height: 2243,
    caption: "One of the coolest bookshops in Boston",
  },
  {
    src: `${process.env.PUBLIC_URL}/gallery/ovids-metamorphasis.jpg`,
    original: `${process.env.PUBLIC_URL}/gallery/ovids-metamorphasis.jpg`,
    width: 3193,
    height: 2209,
    caption: "",
  },
  {
    src: `${process.env.PUBLIC_URL}/gallery/shakespeare-shelley-shaw.jpg`,
    original: `${process.env.PUBLIC_URL}/gallery/shakespeare-shelley-shaw.jpg`,
    width: 2253,
    height: 2940,
    caption: "Inspired by a certain Twilight Zone episode",
  },
];