import { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { images } from "./images";
import styles from "./gallery.module.scss"

const slides = images.map(({ original, width, height }) => ({
  src: original,
  width,
  height,
}));

const VirtualGallery = () => {
    const [index, setIndex] = useState(-1);

    const handleClick = (index, item) => setIndex(index);
  
    return (
        <div>
            <section className={styles.artSection}>
                <h1>Virtual Gallery</h1>
                <p>
                    A collection of my waterolor, gouache, ink, and mix media art works. You can contact me at for art related inquiries here: <a href="mailto:sketchemart@gmail.com" target="blank"> sketchemart@gmail.com</a>.
                </p>
            </section>
            <div className={styles.galleryContainer}>
                <Gallery
                images={images}
                onClick={handleClick}
                enableImageSelection={false}
                />
                <Lightbox
                slides={slides}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                />
            </div>
        </div>
      
    );
}

export default VirtualGallery