import Pokedex from './../Pokedex/pokedex';
import styles from './codeprojects.module.scss';

const CodeProjects = () => {
    return (
        <div>
            <section className={styles.codeSection}>
                <h1>
                    Coding
                </h1>
                This is where you can find my personal coding projects. I am still of the processing of porting from my last portfolio site so currently, this just houses my pokedex. Try looking up some stats on your favorite Gen 1 pokemon.
            </section>
            <Pokedex/>
        </div>
    )
}

export default CodeProjects