import { useEffect, useState, useRef } from 'react';
import styles from "./pokedex.module.scss";
import axios from "axios";
import * as d3 from "d3";


const Pokedex = () => {

    const [pokedex, setPokedex] = useState(null)
    const [pokemon, setPokemon] = useState(null)
    const [color1, setColor1] = useState('')
    const [color2, setColor2] = useState('')
    const [typeTwoBadge, setTypeTwoBadge] = useState(null)
    const [showList, setShowList] = useState(false)
    const [displayList, setDisplayList] = useState(null)

    const searchParam = useRef();

    const getAllPokemon = async () => { 
        try {
            const pokedexData = await axios.get('api/pokedex/');
            setPokedex(pokedexData.data);
            setDisplayList(pokedex);
        } 
        catch {
            console.error()
        }
    }

    useEffect(() => {
        getAllPokemon()
    }, [])

    useEffect(() => {
        if (!!pokemon) {
            if(pokemon?.typeOne !== '') {
                setColor1(colorsByType[pokemon?.typeOne]);
            }
            if(pokemon?.typeTwo !== 'NULL') {
                setColor2(colorsByType[pokemon?.typeTwo]);
                setTypeTwoBadge(<span style={{backgroundColor: color2}} className={`${styles.type} ${styles.typeTwo}`}>{pokemon?.typeTwo}</span>);
            } else {
                setColor2(null)
                setTypeTwoBadge(null)
            }
        }
        
    },[pokemon, color1, color2])

    useEffect(() => {
        if (!!pokemon && !!color1) {
            createBarChart()
        }
    }, [pokemon, color1, color2])

    

    const createBarChart = () => {
        d3.select("svg").selectAll("*").remove();
        if (!!pokemon) {
            const excludeKeys = ['pokemonId', 'typeOne', 'typeTwo'];

            const unit = Object.keys(pokemon)
                .filter(key => !!pokemon[key])
                .filter(key => !excludeKeys.includes(key))
                .filter(key => !isNaN(pokemon[key]))
                .map(key => ({
                    "name": key,
                    "value": pokemon[key]
                }));

            const margin = ({top: 20, right: 0, bottom: 30, left: 40});

       
            const svg = d3.select("svg");

            svg.append('g')
                .attr("viewBox", "0,0,150,420")
                .attr("transform", "translate(0 ," + margin.top + ")");

            svg.selectAll('rect')
                .data(unit)
                .enter()
                .append('g')
                .append('rect')
                .style('fill', color1)
                .attr('x', 0)
                .attr('y', (d,i) => i * 55 + 20 )
                .attr('height', 25)
                .attr('width', d => {
                    return d.value * 1.5;
                });

            svg.selectAll('text.labels')
                .data(unit)
                .enter()
                .append('g')
                .append('text')
                .text(function(d) {
                    if (d.name === 'sp_attack') {
                        d.name = 'Special Attack'
                    }

                    if (d.name === 'sp_defense') {
                        d.name = 'Special Defense'
                    }

                    return  d.name + ": " + d.value;
                })
                .attr('x', 0)
                .attr('y', (d,i) => i * 55 + 17)
                .attr('text-anchor', 'start')
                .attr('fill', '#fff')
                .style('font-size', '1em')
                .style('text-transform', 'Uppercase');
        }
    }

    const submit =  (id) => {;
        const pokemonDetails = pokedex[id-1]
        searchParam.current.value = pokemonDetails.name
        setPokemon({
            pokemonName: pokemonDetails.name,
            pokemonId: pokemonDetails.id,
            typeOne: pokemonDetails.type_one,
            typeTwo: pokemonDetails.type_two,
            "HP": pokemonDetails.hp,
            "Attack": pokemonDetails.attack,
            "Defense": pokemonDetails.defense,
            "Special Attack": pokemonDetails.special_attack,
            "Special Defense": pokemonDetails.special_defense,
            "Speed": pokemonDetails.speed,
        });
        setShowList(false)
    }


    const statsSVG = <div className={pokemon ? styles.svgContainer: null}>
                        {pokemon ? <h2 style={{color: '#fff', textAlign: 'center'}}>STATS</h2> : null}
                        <svg 
                                width={'100%'}
                                height={'330px'}
                                className={styles.statsChart}
                        />
                    </div> ;

    const pokemonId = pokemon?.pokemonId ? <div className={styles.pokemonId}>#{pokemon.pokemonId}</div> : null;
    const pokemonImage = pokemonId ? <img className={styles.pokemonImage} src={`${process.env.PUBLIC_URL}/pokemon/${pokemon.pokemonId}.png`} alt={"pokemon-img"}/> : null;

    let colorsByType = {
        Grass: "#028802",
        Fire: "#EA0707",
        Water: "#2B69FC",
        Bug: "#7A7A00",
        Poison: "#CE00CE",
        Flying: "#009999",
        Normal: "#5D5D00",
        Electric: "#B98E03",
        Rock: "#625751",
        Ground: "#906901",
        Psychic: "#8700D7",
        Ghost: "#605665",
        Fighting: "#B70100",
        Dragon: "#2D5D86",
        Ice: "#00A1CF",
        Fairy: "#D37B84",
        Steel: "#444444"
    };

    const search = () => {
        !showList ? setShowList(true) : null;
        const filteredList = pokedex.filter((pokemon) => pokemon.name.toLowerCase().includes(searchParam.current.value.toLowerCase()));
        setDisplayList(filteredList);
    }

    return(
        <div>
            <div className={styles.pokedex}>
                <div className={styles.pokedexMain}>
                    <div className={styles.pokedexHeader}>
                        <div className={styles.circle}></div>
                        <div>
                            <input placeholder='Search' onKeyUp={() => search()} ref={searchParam}/>
                            {showList && <ul className={styles.suggestions}>
                            {
                                displayList ? displayList?.map((pokemon) => (
                                    <li key={pokemon.id} className={styles.suggestion} onClick={() => submit(pokemon.id)}>
                                        {pokemon.name}
                                    </li>
                                )) : null
                            }
                            </ul>}
                        </div>
                    </div>
                    <div className={styles.pokedexScreen}>
                        <h1 className={styles.pokemonName}>{!!pokemon ? pokemon.pokemonName : '' }</h1>
                        {pokemonImage}
                        {pokemonId}
                        <div className={styles.typesContainer}>
                            {color1 && <span style={{backgroundColor: color1}} className={styles.type}>{pokemon?.typeOne}</span>}
                            {typeTwoBadge !== "NULL" ? typeTwoBadge : null}
                        </div>
                        <div>
                            {statsSVG}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    
}

export default Pokedex;