import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home/Home';
import ErrorBoundary from './errorboundary';
import Navbar from './Navbar/nav';
import VirtualGallery from  './Gallery/gallery';
import styles from './App.module.scss';
import CodeProjects from './CodeProjects/codeProjects';


const App = () => {
  return (
    <Router>
      <Navbar />
      <div className={styles.app}>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="gallery" element={<VirtualGallery/>}/>
        <Route path="code-projects" element={<CodeProjects/>}/>
      </Routes>
      </div>
    </Router>

  );
};

export default App;
