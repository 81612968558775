import React from 'react';
import styles from './home.module.scss';

const Home = () => {
    return(
        <section>
            <p className={styles.nanumGothicRegular}>Hello! Welcome to my website. It is currently under construction so bear with me. 
            My name is Emily Melendez. I am a software engineer and artist. Checkout my virtual gallery here for my latest watercolors, or poke around some of my personal coding projects. More coming soon! </p>
        </section>
    )
}

export default Home