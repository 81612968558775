import React from 'react';
import { Link } from 'react-router-dom';
import styles from './navbar.module.scss'

const Navbar = () => {
  return (
    <nav className={styles.navbar}>
      <ul>
        <li className={styles.home}>
          <Link to="/">Home</Link>
        </li>
        <li  className={styles.gallery}>
          <Link to="/gallery">Gallery</Link>
        </li>
        <li  className={styles.code}>
          <Link to="/code-projects">Code Projects</Link>
        </li>
        {/* <li  className={styles.blog}>
          <Link to="/contact">Blog</Link>
        </li> */}
      </ul>
    </nav>
  );
};

export default Navbar;